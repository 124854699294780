import React from 'react';
import logo from './logo.svg';
import './App.css';
import ScrollDemo from './components/scrollDemo';
import ScrollDemo2 from './components/scrollDemo2';
import Main from './pages/main';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/ZonaPro-Bold.otf';
import './assets/fonts/ZonaPro-ExtraLight.otf';

function App() {
  return (
    <Main />
  );
}

export default App;
