
import React, { useEffect, useState } from "react";
import '../assets/styles/styles.css'
import video1 from '../assets/videos/v1.mp4';
import video2 from '../assets/videos/video2.mp4';
import video3 from '../assets/videos/video3.mp4';
import video4 from '../assets/videos/video4.mp4';
import video5 from '../assets/videos/video3.mp4';
import 'font-awesome/css/font-awesome.min.css';
import Logo from '../assets/images/logo-light.png';
import AboutCover1 from '../assets/images/1.jpg';
import AboutCover2 from '../assets/images/2.jpg';
import AboutCover3 from '../assets/images/3.jpg';
import {
    Modal,
} from "reactstrap";


const Main: React.FC = () => {
    const [currentSection, SetCurrentSection] = useState('')
    const [currentVideoCaption, setCurrentVideoCaption] = useState('')
    const [screenWidth, setScreenWidth] = useState('');
    const [modal_fullscreen, setmodal_fullscreen] = useState(false);

    const handleClicknavbar = () => {
        setmodal_fullscreen(!modal_fullscreen);
    };

    const onScroll = () => {
        window.addEventListener("scroll", function () {
            const fullHeight = document.documentElement.scrollHeight;
            const innerHeight = window.outerHeight;
            const currentTopHeight = window.pageYOffset;
            const height = (fullHeight - innerHeight);
            const postiongRatio = (height - currentTopHeight) / height;
            const percentagePostion = 100 - (postiongRatio * 100);
            console.log('percentagePostion', percentagePostion)
            const screenWidth = window.outerWidth
            console.log("screen-width", screenWidth)
            if (screenWidth < 768) {
                setScreenWidth('small')
                if (percentagePostion < 1.9999999) {
                    SetCurrentSection('section1')
                } else if ((percentagePostion > 2) && (percentagePostion < 18.999999)) {
                    SetCurrentSection('section2-1')
                } else if ((percentagePostion > 19) && (percentagePostion < 34.99999999)) {
                    SetCurrentSection('section2-2')
                }
                else if ((percentagePostion > 35) && (percentagePostion < 47.999999999)) {
                    SetCurrentSection('section3')
                    if ((percentagePostion > 35.0001) && (percentagePostion < 36.999999999)) {
                        setCurrentVideoCaption('section3-1')
                    } else if ((percentagePostion > 37) && (percentagePostion < 38.999999999)) {
                        setCurrentVideoCaption('section3-2')
                    }
                    else if ((percentagePostion > 39) && (percentagePostion < 40.999999999)) {
                        setCurrentVideoCaption('section3-3')
                    }
                    else if ((percentagePostion > 41) && (percentagePostion < 42.999999999)) {
                        setCurrentVideoCaption('section3-4')
                    }
                    else if ((percentagePostion > 43) && (percentagePostion < 45.999999999)) {
                        setCurrentVideoCaption('section3-5')
                    }
                    else if ((percentagePostion > 46) && (percentagePostion < 47.999999999)) {
                        setCurrentVideoCaption('section3-6')
                    }
                }
                else if ((percentagePostion > 48) && (percentagePostion < 62.9999999)) {
                    SetCurrentSection('section4-02')
                }
                else if ((percentagePostion > 63) && (percentagePostion < 74.999999)) {
                    SetCurrentSection('section4')
                    if ((percentagePostion > 63.0001) && (percentagePostion < 64.999999999)) {
                        setCurrentVideoCaption('section4-1')
                    } else if ((percentagePostion > 65) && (percentagePostion < 66.999999999)) {
                        setCurrentVideoCaption('section4-2')
                    } else if ((percentagePostion > 67) && (percentagePostion < 68.999999999)) {
                        setCurrentVideoCaption('section4-3')
                    }
                    else if ((percentagePostion > 69) && (percentagePostion < 70.999999999)) {
                        setCurrentVideoCaption('section4-4')
                    }
                    else if ((percentagePostion > 71) && (percentagePostion < 72.999999999)) {
                        setCurrentVideoCaption('section4-5')
                    }
                    else if ((percentagePostion > 73) && (percentagePostion < 74.999999999)) {
                        setCurrentVideoCaption('section4-6')
                    }
                } else if ((percentagePostion > 75) && (percentagePostion < 85.999999)) {
                    SetCurrentSection('section5')
                }

            } else {
                setScreenWidth('large')
                if (percentagePostion < 4.5) {
                    SetCurrentSection('section1')
                } else if ((percentagePostion > 4.51) && (percentagePostion < 11.999999)) {
                    SetCurrentSection('section2-1')
                } else if ((percentagePostion > 12) && (percentagePostion < 21.999999)) {
                    SetCurrentSection('section2-2')
                }
                else if ((percentagePostion > 22) && (percentagePostion < 48.999999999)) {
                    SetCurrentSection('section3')
                    if ((percentagePostion > 22.011) && (percentagePostion < 26.999999999)) {
                        setCurrentVideoCaption('section3-1')
                    } else if ((percentagePostion > 27) && (percentagePostion < 30.999999999)) {
                        setCurrentVideoCaption('section3-2')
                    } else if ((percentagePostion > 31) && (percentagePostion < 35.999999999)) {
                        setCurrentVideoCaption('section3-3')
                    }
                    else if ((percentagePostion > 36) && (percentagePostion < 40.999999999)) {
                        setCurrentVideoCaption('section3-4')
                    }
                    else if ((percentagePostion > 41) && (percentagePostion < 44.999999999)) {
                        setCurrentVideoCaption('section3-5')
                    }
                    else if ((percentagePostion > 45) && (percentagePostion < 48.999999999)) {
                        setCurrentVideoCaption('section3-6')
                    }
                } else if ((percentagePostion > 49) && (percentagePostion < 54.9999999)) {
                    SetCurrentSection('section4-02')
                }
                else if ((percentagePostion > 55) && (percentagePostion < 76.9999999)) {
                    SetCurrentSection('section4')
                    if ((percentagePostion > 55.011) && (percentagePostion < 58.999999999)) {
                        setCurrentVideoCaption('section4-1')
                    } else if ((percentagePostion > 59) && (percentagePostion < 61.999999999)) {
                        setCurrentVideoCaption('section4-2')
                    } else if ((percentagePostion > 62) && (percentagePostion < 65.999999999)) {
                        setCurrentVideoCaption('section4-3')
                    }
                    else if ((percentagePostion > 66) && (percentagePostion < 70.999999999)) {
                        setCurrentVideoCaption('section4-4')
                    }
                    else if ((percentagePostion > 71) && (percentagePostion < 74.999999999)) {
                        setCurrentVideoCaption('section4-5')
                    }
                    else if ((percentagePostion > 75) && (percentagePostion < 76.999999999)) {
                        setCurrentVideoCaption('section4-6')
                    }
                } else if ((percentagePostion > 77) && (percentagePostion < 84.9999999)) {
                    SetCurrentSection('section5')
                }
            }
        })
    }
    useEffect(() => {
        onScroll();
    }, [])

    console.log('currentSection', currentSection)
    return (
        <div
            className="main-container-bg-color"
        >
            <div className="d-flex flex-wrap justify-content-between transparent-bg-color postion-fixed-header">
                <div className="px-0 transparent-bg-color d-flex flex-wrap justify-content-left">
                    <a href="/"><img src={Logo} className="logo-icon" /></a>
                </div>
                {
                    modal_fullscreen ?
                        <p className="nav-icon nav-icon-close mouse-hover-nav" onClick={() => handleClicknavbar()}><i className="fa fa-close fa-sm"></i></p>
                        :
                        <p className="nav-icon nav-icon-open mouse-hover-nav" onClick={() => handleClicknavbar()}><i className="fa fa-bars fa-sm"></i></p>
                }
            </div>

            <div className={`section-01 show`} id="section-01">
                <video className="mainContainer" autoPlay muted loop id="myVideo">
                    <source src={video4} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                <div className="main-content">
                    <div className="main-header-nyonai-container"><p className="main-header-nyonai">Anything On AI</p></div>
                </div>

            </div>

            <div className={`section-02-1 ${(currentSection == 'section2-1') ? 'show' : null}`} id="section-02">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <h1 className="why-title-2"> WHAT WE DO</h1>
                        <p className="why-title mb-4"> Why Video Captioning ?</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md why-container-1">
                            <div className="why-container-new">
                                <div className="why-background-card-1 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-1">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-2">
                            <div className="why-container-new">
                                <div className="why-background-card-2 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-window-maximize fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-2">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-3">
                            <div className="why-container-new">
                                <div className="why-background-card-3 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-square-o fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-3">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-4">
                            <div className="why-container-new">
                                <div className="why-background-card-4 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-star-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-4">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mt-0 mb-4"></div> */}
                    <div className="row mt-4">
                        <div className="col-md why-container-5">
                            <div className="why-container-new">
                                <div className="why-background-card-5 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-link fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-5">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-6">
                            <div className="why-container-new">
                                <div className="why-background-card-6 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o-notch  fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-5">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-7">
                            <div className="why-container-new">
                                <div className="why-background-card-7 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-paperclip fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-7">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-8">
                            <div className="why-container-new">
                                <div className="why-background-card-8 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-columns fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-8">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`section-02-2 ${(currentSection == 'section2-2') ? 'show' : null}`} id="section-02">
                <div className="section-02-2-layer-2">
                    {
                        (screenWidth == 'small') ?
                            <div className="section-02-subContainer why-margin-left-right">
                                <div className="row">
                                    <p className="why-title mb-4"> Why NyonAi ?</p>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md why-container-1">
                                        <div className="why-container-new">
                                            <div className="why-background-card-1 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-circle-o fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-1">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-2">
                                        <div className="why-container-new">
                                            <div className="why-background-card-2 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-window-maximize fa-sm"></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-2">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-3">
                                        <div className="why-container-new">
                                            <div className="why-background-card-3 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-square-o fa-sm"></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-3">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-4">
                                        <div className="why-container-new">
                                            <div className="why-background-card-4 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-star-o fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-4">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-4"></div>
                                <div className="row">
                                    <div className="col-md why-container-5">
                                        <div className="why-container-new">
                                            <div className="why-background-card-5 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-link fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-5">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-6">
                                        <div className="why-container-new">
                                            <div className="why-background-card-6 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-circle-o-notch  fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-5">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-7">
                                        <div className="why-container-new">
                                            <div className="why-background-card-7 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-paperclip fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-7">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md why-container-8">
                                        <div className="why-container-new">
                                            <div className="why-background-card-8 why-background-card-size">
                                                <p className="icont-text-1 mt-icon-new"><i
                                                    className="fa fa-columns fa-sm "></i>
                                                </p>
                                            </div>
                                            <p className="why-title-1 why-title-color-8">
                                                Lorem Ipsum
                                            </p>
                                            <span>
                                                <p className="why-description-1">
                                                    Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry.
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>

            </div>

            <div className={`section-03 ${(currentSection == 'section3') ? 'show' : null}`} id="section-03">

            </div>
            <div className={`section-04-02 ${(currentSection == 'section4-02') ? 'show' : 'show'}`} id="section-04-02">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        {/* <h1 className="why-title-2"> WHAT WE DO</h1> */}
                        <p className="why-title mb-4"> Social Media Management</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md why-container-1">
                            <div className="why-container-new">
                                <div className="why-background-card-1 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-1">
                                    Lorem Ipsum
                                </p>
                                <span><p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-2">
                            <div className="why-container-new">
                                <div className="why-background-card-2 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-window-maximize fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-2">
                                    Lorem Ipsum
                                </p>
                                <span><p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-3">
                            <div className="why-container-new">
                                <div className="why-background-card-3 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-square-o fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-3">
                                    Lorem Ipsum
                                </p>
                                <span><p className="why-description-1">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-4">
                            <div className="why-container-new">
                                <div className="why-background-card-4 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-star-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-4">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-4"></div>
                    <div className="row">
                        <div className="col-md why-container-5">
                            <div className="why-container-new">
                                <div className="why-background-card-5 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-link fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-5">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-6">
                            <div className="why-container-new">
                                <div className="why-background-card-6 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o-notch  fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-5">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-7">
                            <div className="why-container-new">
                                <div className="why-background-card-7 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-paperclip fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-7">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-8">
                            <div className="why-container-new">
                                <div className="why-background-card-8 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-columns fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-8">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`section-04 ${(currentSection == 'section4') ? 'show' : null}`} id="section-04">
                Social Media management
            </div>

            <div className="section-05 show" id="section-05">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Our Clients</p>
                    </div>
                    <div className="row mt-4">
                        <div className="d-flex justify-content-center">
                            <div className="d-flex flex-row">
                                <div className="box-width border-right d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">ClientFour</span></div>
                                {
                                    (screenWidth == 'large') ? <>
                                        <div className="box-width border-right d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                                        <div className="box-width border-right d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>

                                    </> : null
                                }
                                <div className="box-width border-right d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">ClientOne</span></div>
                                <div className="box-width d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex flex-row">
                                <div className="box-width border-right border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                                {
                                    (screenWidth == 'large') ? <>
                                        <div className="box-width border-right border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">ClientThree</span></div>
                                        <div className="box-width border-right border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                                    </> : null
                                }
                                <div className="box-width border-right border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                                <div className="box-width border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">clientTwo</span></div>
                            </div>
                        </div>
                        {
                            (screenWidth == 'small') ? <>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex flex-row">
                                        <div className="box-width border-right border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                                        <div className="box-width border-right border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">NyonAi</span></div>
                                        <div className="box-width border-top-1 d-flex justify-content-center"><span className="client-font p-4 mt-client-logo zoomInEffect mouse-pointer">ClientThree</span></div>
                                    </div>
                                </div>
                            </>
                                : null
                        }
                    </div>
                </div>

            </div>

            <div className="section-06 show" id="section-06">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-4"> Our Case Studies</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md why-container-1">
                            <div className="why-container-new">
                                <div className="why-background-card-1 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-circle-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-1">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-2">
                            <div className="why-container-new">
                                <div className="why-background-card-2 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-window-maximize fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-2">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-3">
                            <div className="why-container-new">
                                <div className="why-background-card-3 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-square-o fa-sm"></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-3">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div className="col-md why-container-4">
                            <div className="why-container-new">
                                <div className="why-background-card-4 why-background-card-size">
                                    <p className="icont-text-1 mt-icon-new"><i
                                        className="fa fa-star-o fa-sm "></i>
                                    </p>
                                </div>
                                <p className="why-title-1 why-title-color-4">
                                    Lorem Ipsum
                                </p>
                                <span>
                                    <p className="why-description-1">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-07 show " id="section-07">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row">
                        <p className="why-title mb-50"> Contact Us</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md">
                            <p className="contact-us-content mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                            <div className="mt-50">
                                <div className="d-flex flex-row mb-4 mt-md-4">
                                    <div className="border rounded-circle border-3 icon-size">
                                        <p className="font-icon-1 "><i className="fa fa-map-marker"></i></p>
                                    </div>
                                    <p className="contact-us-content mt-3 ms-4">UL Cyber Park . 45245</p>
                                </div>
                                <div className="d-flex flex-row mb-4">
                                    <div className="border rounded-circle border-3 icon-size">
                                        <p className="font-icon-1 "><i className="fa fa-envelope"></i></p>
                                    </div>
                                    <p className="contact-us-content mt-3 ms-4">info@nyonai.com</p>
                                </div>
                                <div className="d-flex flex-row mb-4">
                                    <div className="border rounded-circle border-3 icon-size">
                                        <p className="font-icon-1 "><i className="fa fa-phone"></i></p>
                                    </div>
                                    <p className="contact-us-content mt-3  ms-4">+12 12456454</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-container-1">
                                <div className="form-outline me-md-2 mb-2">
                                    <input type="text" className="form-control input-field" placeholder="Your Name" />
                                </div>
                                <div className="form-outline me-md-2 mb-2">
                                    <input type="text" className="form-control input-field" placeholder="Your Email" />
                                </div>
                                <div className="form-outline mb-2">
                                    <input type="text" className="form-control input-field" placeholder="Your Phone" />
                                </div>
                            </div>
                            <div className="form-outline mt-md-4 mt-0">
                                <textarea rows={9} className="form-control input-field" placeholder="Your Message" />
                            </div>
                            <button className="btn btn-primary submit-btn">Send Message</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-08 show mb-4" id="section-08">
                <div className="section-02-subContainer why-margin-left-right">
                    <div className="row mt-4 mb-4">
                        <p className="why-title mb-50"> About Us</p>
                    </div>
                    <div className="row mt-4 mb-4">
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="card card-aboutUs">
                                <div className="card-body">
                                    <p className="card-title">235</p>
                                    <p className="card-descrition">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="card card-aboutUs">
                                <div className="card-body">
                                    <p className="card-title">26</p>
                                    <p className="card-descrition">Projects</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="card card-aboutUs">
                                <div className="card-body">
                                    <p className="card-title">765</p>
                                    <p className="card-descrition">Ours Of Supports</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mb-4 row-gap-about">
                        <div className="col-md-4 ">
                            <p className="aboutUs-title-2 mt-4">How We Work?</p>
                        </div>
                        <div className="col-md-8 ">
                            <p className="contact-us-content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                        </div>
                    </div>
                    <div className="row mt-4 mb-200">
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="image-aboutUS">
                                <img src={AboutCover1} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="image-aboutUS">
                                <img src={AboutCover2} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="image-aboutUS">
                                <img src={AboutCover3} />
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="" id="section-09">
                <div className="footer-line"></div>
                <footer className="black-bg text-center text-white">
                    <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', height: '100%' }}>
                        © 2023 Copyright:
                        <a className="text-white ms-4" href="#">Nyonai.com</a>
                    </div>
                </footer>
            </div>
            {
                ((currentSection == 'section3') && (screenWidth == 'large')) ?
                    <div className={`section-03-1 ${(currentSection == 'section3') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">

                        <div className="section-03-1-1 custom-height">
                            {/* <p className="why-title margin-bottom-header"> Video Captioning</p> */}
                            <div className="video-box">
                                {/* <img src={image1} className="video-image" id="vid-img" /> */}
                                {/* <video className="video-image" id="vid-img" autoPlay controls>
                                    <source src={video2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}

                                <video
                                    className="video-image  margin-bottom-video"
                                    playsInline
                                    loop
                                    muted
                                    autoPlay={true}
                                    src={`${(currentVideoCaption == 'section3-1') ? video1 : (currentVideoCaption == 'section3-2') ? video2 : (currentVideoCaption == 'section3-3') ? video3 : (currentVideoCaption == 'section3-4') ? video4 : (currentVideoCaption == 'section3-5') ? video5 : (currentVideoCaption == 'section3-6') ? video1 : null}`}
                                />
                            </div>
                        </div>
                        <div className="section-03-1-2">
                            <div className="video-subtitle-box">
                                <div className={`${(currentVideoCaption == 'section3-1') ? 'active-text' : null}`} id="text1"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Video Subtitle 1</div>
                                <div className={`${(currentVideoCaption == 'section3-2') ? 'active-text' : null}`} id="text2"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Video Subtitle 2</div>
                                <div className={`${(currentVideoCaption == 'section3-3') ? 'active-text' : null}`} id="text3"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Third goes here</div>
                                <div className={`${(currentVideoCaption == 'section3-4') ? 'active-text' : null}`} id="text4"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Fourth goes here</div>
                                <div className={`${(currentVideoCaption == 'section3-5') ? 'active-text' : null}`} id="text5"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Fifth goes here</div>
                                <div className={`${(currentVideoCaption == 'section3-6') ? 'active-text' : null}`} id="text5"><i className="fa fa-hand-o-right  fa-sm me-2"></i> sixth goes here</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                ((currentSection == 'section4') && (screenWidth == 'large')) ?
                    <div className={`section-03-1 ${(currentSection == 'section4') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">

                        <div className="section-03-1-1 custom-height">
                            {/* <p className="why-title margin-bottom-header"> Social Media Management</p> */}
                            <div className="video-box">

                                <video
                                    className="video-image  margin-bottom-video"
                                    playsInline
                                    loop
                                    muted
                                    autoPlay={true}
                                    src={`${(currentVideoCaption == 'section4-1') ? video1 : (currentVideoCaption == 'section4-2') ? video2 : (currentVideoCaption == 'section4-3') ? video3 : (currentVideoCaption == 'section4-4') ? video4 : (currentVideoCaption == 'section4-5') ? video5 : (currentVideoCaption == 'section4-6') ? video2 : null}`}
                                />
                            </div>
                        </div>
                        <div className="section-03-1-2">
                            <div className="video-subtitle-box">
                                <div className={`${(currentVideoCaption == 'section4-1') ? 'active-text' : null}`} id="text1"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Social Media Sub 1</div>
                                <div className={`${(currentVideoCaption == 'section4-2') ? 'active-text' : null}`} id="text2"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Social Media Sub 2</div>
                                <div className={`${(currentVideoCaption == 'section4-3') ? 'active-text' : null}`} id="text3"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Third title goes here</div>
                                <div className={`${(currentVideoCaption == 'section4-4') ? 'active-text' : null}`} id="text4"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Fourth title goes here</div>
                                <div className={`${(currentVideoCaption == 'section4-5') ? 'active-text' : null}`} id="text5"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Fifth title goes here</div>
                                <div className={`${(currentVideoCaption == 'section4-6') ? 'active-text' : null}`} id="text6"><i className="fa fa-hand-o-right  fa-sm me-2"></i> Sixth title goes here</div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            {
                ((currentSection == 'section2-2') && (screenWidth == 'large')) ?
                    <div className="fixed-position-02-2">
                        <div className={`section-02-subContainer why-margin-left-right`} >
                            <div className="row">
                                <p className="why-title mb-4"> Why NyonAi ?</p>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md why-container-1">
                                    <div className="why-container-new">
                                        <div className="why-background-card-1 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-circle-o fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-1">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md why-container-2">
                                    <div className="why-container-new">
                                        <div className="why-background-card-2 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-window-maximize fa-sm"></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-2">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md why-container-3">
                                    <div className="why-container-new">
                                        <div className="why-background-card-3 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-square-o fa-sm"></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-3">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md why-container-4">
                                    <div className="why-container-new">
                                        <div className="why-background-card-4 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-star-o fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-4">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row mt-4 mb-4"></div> */}
                            <div className="row mt-md-4 mt-0">
                                <div className="col-md why-container-5">
                                    <div className="why-container-new">
                                        <div className="why-background-card-5 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-link fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-5">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md why-container-6">
                                    <div className="why-container-new">
                                        <div className="why-background-card-6 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-circle-o-notch  fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-5">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md why-container-7">
                                    <div className="why-container-new">
                                        <div className="why-background-card-7 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-paperclip fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-7">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md why-container-8">
                                    <div className="why-container-new">
                                        <div className="why-background-card-8 why-background-card-size">
                                            <p className="icont-text-1 mt-icon-new"><i
                                                className="fa fa-columns fa-sm "></i>
                                            </p>
                                        </div>
                                        <p className="why-title-1 why-title-color-8">
                                            Lorem Ipsum
                                        </p>
                                        <span>
                                            <p className="why-description-1">
                                                Lorem Ipsum is simply dummy text of the printing and
                                                typesetting industry.
                                            </p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                ((currentSection == 'section3') && (screenWidth == 'small')) ?
                    <div className={`section-03-1 ${(currentSection == 'section3') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">
                        <div className={`section-03-1 top-margin-md`} id="section-03-1">

                            <div className="col">
                                {/* <div className="col-md">
                                    <p className="why-title-sm"> Video Captioning</p>
                                </div> */}
                                <div className="col-md flex-style">
                                    <div className="section-03-1-1 flex-style">
                                        <div className="video-box " >
                                            {/* <img src={image1} className="video-image" id="vid-img" /> */}
                                            {/* <video className="video-image" id="vid-img" autoPlay controls>
                                    <source src={video2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                                            <video
                                                className="video-image"
                                                playsInline
                                                loop
                                                muted
                                                autoPlay={true}
                                                // src={video1}
                                                src={`${(currentVideoCaption == 'section3-1') ? video1 : (currentVideoCaption == 'section3-2') ? video2 : (currentVideoCaption == 'section3-3') ? video3 : (currentVideoCaption == 'section3-4') ? video4 : (currentVideoCaption == 'section3-5') ? video5 : (currentVideoCaption == 'section3-6') ? video2 : null}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="section-03-1-2">
                                        <div className="video-subtitle-box">
                                            <div className={`${(currentVideoCaption == 'section3-1') ? 'active-text' : null}`} id="text1">Subtitle one goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-2') ? 'active-text' : null}`} id="text2">Subtitle two goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-3') ? 'active-text' : null}`} id="text3">Third Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-4') ? 'active-text' : null}`} id="text4">Fourth Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-5') ? 'active-text' : null}`} id="text5">Fifth Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section3-6') ? 'active-text' : null}`} id="text5">Fifth Subtitle goes here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                ((currentSection == 'section4') && (screenWidth == 'small')) ?
                    <div className={`section-03-1 ${(currentSection == 'section4') ? 'postion-fixed' : 'hidden'}`} id="section-03-1">
                        <div className={`section-03-1 top-margin-md`} id="section-03-1">

                            <div className="col">
                                {/* <div className="col-md">
                                    <p className="why-title-sm"> Social Media Management</p>
                                </div> */}
                                <div className="col-md flex-style">
                                    <div className="section-03-1-1 flex-style">
                                        <div className="video-box">
                                            {/* <img src={image1} className="video-image" id="vid-img" /> */}
                                            {/* <video className="video-image" id="vid-img" autoPlay controls>
                                    <source src={video2} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                                            <video
                                                className="video-image"
                                                playsInline
                                                loop
                                                muted
                                                autoPlay={true}
                                                // src={video1}
                                                src={`${(currentVideoCaption == 'section4-1') ? video1 : (currentVideoCaption == 'section4-2') ? video2 : (currentVideoCaption == 'section4-3') ? video3 : (currentVideoCaption == 'section4-4') ? video4 : (currentVideoCaption == 'section4-5') ? video5 : (currentVideoCaption == 'section4-6') ? video3 : null}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="section-03-1-2">
                                        <div className="video-subtitle-box">
                                            <div className={`${(currentVideoCaption == 'section4-1') ? 'active-text' : null}`} id="text1">Subtitle one goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-2') ? 'active-text' : null}`} id="text2">Subtitle two goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-3') ? 'active-text' : null}`} id="text3">Third Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-4') ? 'active-text' : null}`} id="text4">Fourth Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-5') ? 'active-text' : null}`} id="text5">Fifth Subtitle goes here</div>
                                            <div className={`${(currentVideoCaption == 'section4-6') ? 'active-text' : null}`} id="text5">Fifth Subtitle goes here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                    setmodal_fullscreen(!modal_fullscreen);
                }}
                className="modal-fullscreen"
            >
                <div className="main-modal-container">
                    <div className="">
                        <video className="mainContainer" autoPlay muted loop id="myVideo">
                            <source src={video2} type="video/mp4" />
                            Your browser does not support HTML5 video.
                        </video>
                        <div className="main-content-nav">
                            <div className="row gx-0 nav-container-height margin-top">
                                <a href="#section-03" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border menu-top-border min-height-col ">
                                    <p className="nav-text-new ">Video Captioning</p>
                                </a>
                                <a href="#section-04" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border min-height-col">
                                    <p className="nav-text-new ">Social Media Management</p>
                                </a>
                                <a href="#section-05" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border min-height-col">
                                    <p className="nav-text-new ">Our Clients</p>
                                </a>
                                <a href="#section-06" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style menu-right-border menu-bottom-border min-height-col">
                                    <p className="nav-text-new ">Our Case Studies</p>
                                </a>
                                <a href="#section-08" onClick={() => {
                                    setmodal_fullscreen(!modal_fullscreen);
                                }}
                                    className="col-md col-style min-height-col menu-bottom-border">
                                    <p className="nav-text-new ">Who we are</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    );
};

export default Main;
